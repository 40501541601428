<template>
    <div class="col-md-4">
        <b-card title="功能選項" sub-title="本頁面提供各種設定">
            <b-form-group label="換房功能">
                <b-form-checkbox v-model="options.roomSwitch" switch>
                    是否啟用？ <b>(目前狀態: {{ options.roomSwitch? '啟用': '停用' }})</b>
                </b-form-checkbox>
            </b-form-group>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "FunctionSwitch",
        data: function () {
            return {
                options:{
                    roomSwitch: true
                }
            }

        }
    }
</script>

<style scoped>

</style>